.conversation-list-table tr td,
.conversation-list-table tr th {
  text-align: center;
}
.conversation-list-table tr th {
  background: #00096b;
  color: #fff;
  border-right: 1px solid #fff;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #888c8e !important;
}
.unreadMessags {
  position: absolute;
  z-index: 99;
  right: -20px;
  color: #fff;
  background: #000;
  border-radius: 50%;
  padding: 2px 8px;
  top: -10px;
  font-weight: bold;
}

.face:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.bellIconcont svg {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  padding: 10px;
  /* border-radius: 5px; */
}
.sub-menu-btns a button {
  background: #36396c;
}
.sub-menu-btns {
  text-align: right;
  display: flex;
  justify-content: right;
  flex-direction: row;
  /* row-gap: 20px; */
  column-gap: 20px;
}

.align-right-menu {
  margin-left: auto;
  display: flex;
  column-gap: 15px;
}

.align-right-menu a button {
  background: #247ad4;
}
